import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import {MessageStatus} from "../../models/private-messaging-instance";
import firebase from "firebase/app";
import MessageInstanceItem from "./message-instance-item";
import MessageWithAdmin from "./message-with-admin";
import {useMatch} from "@reach/router";
import {project} from "../../utils/constant.urls";

const styles = {
  list: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    // overflowY: "scroll",
    maxHeight: "900px",
  },
  list1: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    maxHeight: "900px",
  },
};

const MessageInstanceList = () => {
  const {userData} = useSelector((state: RootState) => state.userData);
  const [messagingInstances, setInstances] = useState<MessageStatus[]>([]);
  const [messagingAdmin, setMessagingAdmin] = useState<MessageStatus[]>([]);
  const msgMatch = useMatch("/messaging/:id");

  useEffect(() => {
    if (userData) {
      let messagingListStream = firebase
        .firestore()
        .collectionGroup("messaging")
        .where("users", "array-contains", userData.uid)
        .where("project", "==", project)
        .orderBy("updatedAt", "desc")
        .limit(30)
        .onSnapshot((snapshot) => {
          updateMessagingList(snapshot);
        });

      return () => {
        messagingListStream();
      };
    }
  }, []);
  useEffect(() => {
    if (userData) {
      let adminMessagingStream = firebase
        .firestore()
        .collection("messaging_with_admin")
        .doc(`admin_${userData.uid}`)
        .onSnapshot((snapshot) => {
          updateMessagingAdmin(snapshot);
        });

      return () => {
        adminMessagingStream();
      };
    }
  }, []);

  const updateMessagingList = (snapshot) => {
    let t: MessageStatus[] = [];
    snapshot.docs.forEach((d) => {
      let data = d.data();
      data["id"] = d.id;
      t.push(new MessageStatus(data));
    });
    setInstances(t);
  };
  const updateMessagingAdmin = (snapshot) => {
    let adminChat: MessageStatus[] = [];
    if (snapshot) {
      let data = snapshot.data();
      data["id"] = snapshot.id;
      adminChat.push(new MessageStatus(data));
    }

    setMessagingAdmin(adminChat);
  };

  return (
    <>
      <div
        style={{
          ...(styles.list1 as React.CSSProperties),
        }}
      >
        {messagingAdmin.map((status) => {
          if (status.admin != null && status.admin === "admin") {
            return <MessageWithAdmin status={status} key={status?.id} />;
          }
        })}
      </div>

      <div
        style={{
          ...(styles.list as React.CSSProperties),
        }}
      >
        {messagingInstances.map((status) => {
          return <MessageInstanceItem status={status} key={status?.id} />;
        })}
      </div>
    </>
  );
};

export default MessageInstanceList;
