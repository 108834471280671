import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Text from "../../components/data-display/text";
import { RootState } from "../../redux/index.reducers";
import { MessageStatus } from "../../models/private-messaging-instance";
import moment from "moment";
import { Link, useParams, useMatch } from "@reach/router";
import {useTheme} from "@mui/system";
import Avatar from "../../components/avatar/avatar";
import { UserData } from "../../models/user-data";
import { users } from "../../utils/firebase.utils";
import {environment} from "../../environments/quickTaskEnvironemt";
import {isQuickTaskProject, project} from "../../utils/constant.urls";
  
const QuikTaskMainColor = environment.mainColor;



const MessageInstanceItem = ({ status }: { status: MessageStatus }) => {
  const msgMatch = useMatch("/messaging/:id");
  const { userData } = useSelector((state: RootState) => state.userData);
  const theme = useTheme();
  const styles = {
    item: {
      padding: "10px 8px",
      background: "white",
      borderRadius: "7px",
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "start",
      justifyContent: "start",
      cursor: "pointer",
      transition: "0.3s",
      "&:hover": {
        background: "#f5f6f7",
      },
    },
    itemNew: {
      padding: "10px 8px",
      borderRadius: "7px",
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "start",
      justifyContent: "start",
      cursor: "pointer",
      transition: "0.3s",
      background: "#f5f6f7",
      "&:hover": {
        background: "#f5f6f7",
      },
    },
  };
  const instanceRef = useRef<HTMLDivElement>(null);
  const [otherUser, setOtherUser] = useState<UserData>(null);

  const hasNew = (status?.unRead[userData?.uid] ?? 0) > 0;

  useEffect(() => {
    let uid = status?.users.find((a) => a !== userData?.uid);
    if (!uid) {
      console.log("not found  that user id");
      return;
    }

    users
      .doc(uid)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("not found that doc");
          return;
        }

        let data = new UserData(doc?.data());
        if (!data || data.project !== project) {
          console.log("not found data in that project or no data");
          return;
        }
        data["id"] = doc?.id;
        setOtherUser(new UserData(data));
      });
  }, []);

  return (
    <>
      <Link to={"/messaging/" + status.id}>
        <div
          style={{
            ...(msgMatch?.id === status.id
              ? {...(styles.itemNew as React.CSSProperties)}
              : {...(styles.item as React.CSSProperties)}),
          }}
          ref={instanceRef}
        >
          <div style={{width: "6px", flexShrink: 0}} />
          <div style={{position: "relative"}}>
            <Avatar
              userId={otherUser?.slug_title}
              name={otherUser?.name}
              profilePicture={otherUser?.profilePicture}
              size={32}
              style={{position: "relative", zIndex: 1}}
            />
          </div>
          <div style={{width: "12px", flexShrink: 0}} />
          <div style={{opacity: !hasNew ? 0.8 : 1}}>
            <Text variant={"caption"} regular style={{color: "black"}}>
              {status.title}
            </Text>
            <div style={{height: "4px"}} />
            <Text
              variant={"body2"}
              component={"p"}
              bold
              style={{
                color: !hasNew
                  ? "black"
                  : isQuickTaskProject
                  ? QuikTaskMainColor
                  : theme.palette.primary.main,
              }}
            >
              {otherUser?.name ?? " "}
            </Text>
            <div
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: `${(instanceRef?.current?.clientWidth ?? 200) - 86}px`,
              }}
            >
              <Text
                variant={"caption"}
                component={"p"}
                medium={hasNew}
                regular={!hasNew}
                style={{
                  color: !hasNew
                    ? "black"
                    : isQuickTaskProject
                    ? QuikTaskMainColor
                    : theme.palette.primary.main,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {status?.lastMessage?.uid === userData?.uid ? "You: " : ""}
                {status?.lastMessage?.message?.includes("Attachment")
                  ? status?.lastMessage?.message.replace("Image", "Media")
                  : status.lastMessage.message}
              </Text>
            </div>
            <div style={{height: "12px"}} />
            <Text
              variant={"subtitle1"}
              component={"p"}
              regular
              style={{
                color: !hasNew
                  ? "grey"
                  : isQuickTaskProject
                  ? QuikTaskMainColor
                  : theme.palette.primary.main,
              }}
            >
              {moment(status.updatedAt).fromNow(false)}
            </Text>
          </div>
        </div>
        <div
          style={{
            height: "1px",
            width: "100%",
            background: "rgb(240,240,240)",
          }}
        />
      </Link>
    </>
  );
};

export default MessageInstanceItem;
