import React, {useEffect, useRef, useState , FC} from "react";
import {useMatch} from "@reach/router";
import {AdminMessage, tasks} from "../../utils/firebase.utils";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import {Message} from "../../models/private-message";
import MessageListItem from "./message-list-item";
import Text from "../../components/data-display/text";
import moment from "moment";
import { MessageStatus } from "../../models/private-messaging-instance";
import {longDateFormatWith_DDWithTime} from "../../utils/constant.urls";

const Fade = require('react-reveal/Fade');

const MessageViewerList = () => {

    const msgMatch= useMatch('/messaging/:id');

    const sentinel = useRef<HTMLDivElement>(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [last, setLast] = useState(null);
    const [flag, setFlag] = useState(false);
    const { userData } = useSelector((state : RootState) => state.userData);
    const messagesRef = useRef([]);
    const [isAdminType, setIsAdminType] = useState<boolean>(false);
    // const [messagingAdmin, setMessagingAdmin] = useState<MessageStatus[]>([]);

    messagesRef.current = messages;

    const lastRef = useRef();
    lastRef.current = last;

    const flagRef = useRef(false);
    flagRef.current = flag;

    let taskId = msgMatch?.id?.split("_")[0];
    let offerId = msgMatch?.id?.split("_")[1];

    useEffect(() => {
        let isAdmin: boolean = msgMatch.id.includes('admin') ? true : false;
        setIsAdminType(isAdmin)
        setMessages([]);
      if (isAdmin === false) {
        let messageStream = tasks
          .doc(taskId)
          .collection("offers")
          .doc(offerId)
          .collection("messaging")
          .doc(msgMatch?.id)
          .collection("messages")
          .orderBy("createAt", "desc")
          .limit(20)
          .onSnapshot((snapshot) => {
            updateMessages(snapshot);
          });

        return () => {
          messageStream();
        };
      } else if (isAdmin === true) {
        let messageStreamAdmin = AdminMessage.doc(`admin_${userData.uid}`)
          .collection("messages")
          .orderBy("createAt", "desc")
          .onSnapshot((snapshot) => {
            updateMessagesAdmin(snapshot);
          });

        return () => {
          messageStreamAdmin();
        };
      }
      
     
    }, [msgMatch?.id])

    useEffect(()=>{
       
       
    },[msgMatch?.id])

    const updateMessages = (snapshot) => {
        if (snapshot.docChanges().length === 0) {
          setMessages([]);
        } else {
          let lastDocumentSnapshot = null;
          let messagesList = [...messagesRef.current];
          snapshot.docChanges().forEach((d) => {
            switch (d.type) {
              case "added":
                let data = d.doc.data();
                data["id"] = d.doc.id;
                if (
                  messagesList.length > 0 &&
                  messagesList[messagesList.length - 1].createAt < new Message(data).createAt
                ) {
                  messagesList.push(new Message(data));
                } else {
                  messagesList.splice(0, 0, new Message(data));
                }
                if (!lastRef.current) lastDocumentSnapshot = d.doc;
                break;
              case "removed":
              case "modified":
                let modifiedData = d.doc.data();
                modifiedData["id"] = d.doc.id;
                let index = messagesList.findIndex((value) => value.id === d.doc.id);
                if (index !== -1) {
                  messagesList[index] = new Message(modifiedData);
                }
                break;
            }
          });

          tasks
            .doc(taskId)
            .collection("offers")
            .doc(offerId)
            .collection("messaging")
            .doc(taskId + "_" + offerId)
            .update({
              [`unRead.${userData?.uid}`]: 0,
              [`lastRead.${userData?.uid}`]: new Date(),
            })
            .then();

          setMessages(messagesList);
          if (!lastRef.current && !flagRef.current) {
            setLast(lastDocumentSnapshot);
            setFlag(true);
          }
        }
    }

    const updateMessagesAdmin = (snapshot) => {
        if (snapshot.docChanges().length === 0) {
          setMessages([]);
        } else {
          let lastDocumentSnapshot = null;
          let messagesList = [...messagesRef.current];
          snapshot.docChanges().forEach((d) => {
            switch (d.type) {
              case "added":
                let data = d.doc.data();
                data["id"] = d.doc.id;
                if (
                  messagesList.length > 0 &&
                  messagesList[messagesList.length - 1].createAt < new Message(data).createAt
                ) {
                  messagesList.push(new Message(data));
                } else {
                  messagesList.splice(0, 0, new Message(data));
                }
                if (!lastRef.current) lastDocumentSnapshot = d.doc;
                break;
              case "removed":
              case "modified":
                let modifiedData = d.doc.data();
                modifiedData["id"] = d.doc.id;
                let index = messagesList.findIndex((value) => value.id === d.doc.id);
                if (index !== -1) {
                  messagesList[index] = new Message(modifiedData);
                }
                break;
            }
          });

          AdminMessage.doc(`admin_${userData.uid}`)
            .update({
              [`unRead.${userData?.uid}`]: 0,
              [`lastRead.${userData?.uid}`]: new Date(),
            })
            .then();

          setMessages(messagesList);
          if (!lastRef.current && !flagRef.current) {
            setLast(lastDocumentSnapshot);
            setFlag(true);
          }
        }
    }

    useEffect(() => {
        let observer = new IntersectionObserver(onSentinelIntersection);
        if (sentinel.current)
            observer.observe(sentinel.current);

        return function cleanup() {
            observer.disconnect();
        };
    });

    const onSentinelIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if (lastRef.current) {
                    let taskId =msgMatch?.id.split("_")[0];
                    let offerId = msgMatch?.id.split("_")[1];
                    tasks
                        .doc(taskId)
                        .collection("offers")
                        .doc(offerId)
                        .collection("messaging")
                        .doc(msgMatch?.id)
                        .collection("messages")
                        .orderBy("createAt", 'desc')
                        .startAfter(lastRef.current)
                        .limit(20)
                        .onSnapshot((snapshot) => {
                            let lastDocumentSnapshot = null;
                            let messagesList = [...messagesRef.current];

                            if (snapshot.docs.length !== 0) {
                              snapshot.docs.forEach((d) => {
                                let data = d.data();
                                data["id"] = d.id;
                                messagesList.splice(0, 0, new Message(data));
                                lastDocumentSnapshot = d;
                              });
                            }
                            setMessages(messagesList);
                            setLast(lastDocumentSnapshot);
                        });
                }
            }
        })
    };

    return (
        <div style={{ display: "flex", flexDirection: "column-reverse", height: '100%', overflowY: "auto" }}>
            {
                messages.length > 0 &&
                <div>
                    <div style={{ height: '150px' }}/>
                    {
                        messages.map((message, index) => {
                            return (
                              <div
                                key={index}
                                ref={sentinel}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems:
                                    message?.uid === userData?.uid ? "flex-end" : "flex-start",
                                }}
                              >
                                {(index === 0 ||
                                  (index !== 0 &&
                                    message.createAt.getTime() -
                                      messages[index - 1].createAt.getTime() >
                                      3600000)) && (
                                  <div
                                    style={{
                                      margin: "8px 0",
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Text variant={"caption"} style={{color: "grey"}} regular>
                                      {moment(message.createAt).format(
                                        longDateFormatWith_DDWithTime
                                      )}
                                    </Text>
                                  </div>
                                )}
                                <Fade bottom distance={"12px"} key={index} duration={300}>
                                  {/* <div ref={sentinel} /> */}
                                  <MessageListItem
                                    key={message.id}
                                    message={message}
                                    messages={messages[index - 1]}
                                    next={index >= messages.length - 1 ? null : messages[index + 1]}
                                    previous={index > 0 ? messages[index - 1] : null}
                                  />
                                  {/* <div ref={sentinel} /> */}
                                </Fade>
                              </div>
                            );
                        })
                    }
                    <div style={{ height: '100px' }}/>
                </div>
            }
        </div>
    )
}

export default MessageViewerList;